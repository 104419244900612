const theme = {
  colors: {
    text: "#000000",
    primary: "#58b9ee",
    secondary: "#414757",
    error: "#f13a59",
    underlineColor: "transparent",
  },
};

export default theme;
