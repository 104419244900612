import Navbar from "../components/NavBars/Navbar";
import React, { useState, useEffect } from "react";
import SideNavbar from "../components/NavBars/SideNavbar";
import Footer from "../components/Footer";
import MainHeader from "../components/Headers/MainHeader";
import Global from "../utils/Global";
import TreatmentSearchableDropdown from "../components/Inputs/TreatmentSearchableDropdown";
import MultiPrimarySearchableDropdown from "../components/Inputs/MultiPrimarySearchableDropdown";
import sex_options from "../config/sex_options";
import { useNavigate } from "react-router-dom";
import Subject from "../utils/Subject";
import Form from "../utils/Form/Form";
import ErrorPopup from "../components/Modals/ErrorPopup";
import store from "../utils/Redux/store";
import { updateUserSubjects } from "../utils/Redux/actions";
import AccountTypeRadioButtons from "../components/Inputs/AccountTypeRadioButtons";
import TreatmentStatusRadioButtons from "../components/Inputs/TreatmentStatusRadioButtons";
import patient_account_types from "../config/patient_account_types";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
export default function AddSubject() {
  let navigate = useNavigate(); 
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  const onCancelPressed = () => {
    navigate("/dashboard");
  };

  const onAddPressed = async () => {
    if (verifying) {
      return;
    }
    setVerifying(true);

    try {
      const new_subject = Subject.createFromForm(subjectForm);
      Global.localAppHandler
        .app_post_subject(new_subject)
        .then((returnStatus) => {
          // deleting saved list so that when a subject is updated, the UI is forced to reload the list on the dashboard.
          store.dispatch(updateUserSubjects(null));
          navigate("/dashboard");
        })
        .catch((returnStatus) => {
          if (returnStatus.message?.includes("UsernameExistsException")) {
            setErrorMessage("Phone number already in use. Enter another phone number.")
          } else {
            setErrorMessage("There was an error, please try again.");
          }
          console.log(returnStatus);
          setShowError(true);
        });
    } catch (error) {
      setSubjectForm(Form.copy(error.form));
      console.log(error.form);
      setVerifying(false);
      return;
    }
    setVerifying(false);
  };
  const [subjectForm, setSubjectForm] = useState(Subject.createForm());


  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    Global.localAppHandler
      .app_get_subject_name()
      .then((subject_name) => {
        subjectForm.name.handleInputChange(subject_name);
        setSubjectForm(Form.copy(subjectForm));
      })
      .catch((returnStatus) => {
        // TODO: maybe pop up an error here for if this call fails, but should never happen
        navigate("/dashboard");
      });
  }, []);

  useEffect(() => {
    document.body.classList.add("nav-fixed");
    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
      <SideNavbar patientSubHeaderEnabled={false} activeTab={{ studyOverview: false, subjects: true, manageTeam: false, reviewReports: false, remoteAlerts: false }} />
        <div id="layoutSidenav_content">
          <main>
            <MainHeader title="Add Subject" />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <div className="card mb-4">
                <div className="card-header">Demographic Information</div>
                <div className="card-body">
                  {/* Form Group (username)*/}
                  <div className="mb-3">
                    <label className="small mb-1" htmlFor="inputUsername">
                      Subject ID
                    </label>
                    <input
                      className="form-control mb-2"
                      id="inputUsername"
                      type="text"
                      // placeholder="Enter your first name"
                      disabled={true}
                      value={subjectForm.name.getUI()}
                      onChange={(event) => {
                        subjectForm.name.handleInputChange(event.target.value);
                        setSubjectForm(Form.copy(subjectForm));
                      }}
                    />
                    {subjectForm.name.error && <div className="tiny alert alert-danger">{subjectForm.name.error}</div>}
                  </div>
                  {/* Form Row*/}
                  <div className="row gx-3 mb-3">
                    {/* Form Group (first name)*/}
                    <div className="col-md-3">
                      <label className="small mb-1" htmlFor="inputAge">
                        Age (years):
                      </label>
                      <input
                        className="form-control mb-2"
                        id="inputAge"
                        type="number"
                        min={0}
                        max={130}
                        value={isNaN(subjectForm.age.getUI()) ? "" : subjectForm.age.getUI()}
                        onKeyPress={(e) => {
                          // Allow only digits and prevent hyphens and other special characters
                          const allowedCharacters = /^[0-9]+$/;
                          if (!allowedCharacters.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          subjectForm.age.handleInputChange(parseInt(event.target.value));
                          setSubjectForm(Form.copy(subjectForm));
                        }}
                      />
                      {subjectForm.age.error && <div className="tiny alert alert-danger">{subjectForm.age.error}</div>}
                    </div>
                    {/* Form Group (last name)*/}
                    <div className="col-md-3">
                      <label className="small mb-1" htmlFor="inputHeight">
                        Height (in):
                      </label>
                      <input
                        className="form-control mb-2"
                        id="inputHeight"
                        type="number"
                        min={0}
                        max={108}
                        value={isNaN(subjectForm.height.getUI()) ? "" : subjectForm.height.getUI()}
                        onKeyPress={(e) => {
                          // Allow only digits and prevent hyphens and other special characters
                          const allowedCharacters = /^[0-9]+$/;
                          if (!allowedCharacters.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          subjectForm.height.handleInputChange(parseInt(event.target.value));
                          setSubjectForm(Form.copy(subjectForm));
                        }}
                      />
                      {subjectForm.height.error && <div className="tiny alert alert-danger">{subjectForm.height.error}</div>}
                    </div>
                    <div className="col-md-3">
                      <label className="small mb-1" htmlFor="inputWeight">
                        Weight (lbs):
                      </label>
                      <input
                        className="form-control mb-2"
                        id="inputWeight"
                        type="number"
                        min={0}
                        max={1400}
                        value={isNaN(subjectForm.weight.getUI()) ? "" : subjectForm.weight.getUI()}
                        onKeyPress={(e) => {
                          // Allow only digits and prevent hyphens and other special characters
                          const allowedCharacters = /^[0-9]+$/;
                          if (!allowedCharacters.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          subjectForm.weight.handleInputChange(parseInt(event.target.value));
                          setSubjectForm(Form.copy(subjectForm));
                        }}
                      />
                      {subjectForm.weight.error && <div className="tiny alert alert-danger">{subjectForm.weight.error}</div>}
                    </div>
                    <div className="col-md-3 align-items-end">
                      <div className="dropdown mb-2 mt-4">
                        <button
                          className="btn btn-primary dropdown-toggle"
                          id="dropdownFadeInUp"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {subjectForm.sex.getUI() ? subjectForm.sex.getUI() : "Sex"}
                        </button>
                        <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                          {sex_options.sex.map((option, index) => (
                            <div
                              key={index}
                              className="dropdown-item"
                              onClick={(event) => {
                                subjectForm.sex.handleInputChange(event.target.innerText);
                                setSubjectForm(Form.copy(subjectForm));
                              }}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      </div>
                      {subjectForm.sex.error && <div className="tiny alert alert-danger">{subjectForm.sex.error}</div>}
                    </div>
                  </div>

                  {/* Form Row        */}
                  <MultiPrimarySearchableDropdown
                    placeholder={"Select ICD-10-CM Code"}
                    onItemAdded={(item) => {
                      subjectForm.primary_conditions.handleInputChangeListAdd(item);
                      setSubjectForm(Form.copy(subjectForm));
                    }}
                    onItemReplaced={(item) => {
                      subjectForm.primary_conditions.handleInputChangeListReplace(item);
                      setSubjectForm(Form.copy(subjectForm));
                    }}
                    onItemDeleted={(item) => {
                      subjectForm.primary_conditions.handleInputChangeListRemove(item);
                      setSubjectForm(Form.copy(subjectForm));
                    }}
                    primaryConditions={subjectForm.primary_conditions.getUI()}
                    errorText={subjectForm.primary_conditions.error}
                  />
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header">Treatment Status:</div>
                <div className="card-body">
                  <TreatmentStatusRadioButtons
                    treatmentStatus={subjectForm.treatment_status.getUI()}
                    handleSelect={(option) => {
                      subjectForm.treatment_status.handleInputChange(option);
                      setSubjectForm(Form.copy(subjectForm));
                    }}
                  />

                  <TreatmentSearchableDropdown
                    placeholder={"Select CPT Code"}
                    onItemAdded={(item) => {
                      subjectForm.treatments.handleInputChangeListAdd(item);
                      setSubjectForm(Form.copy(subjectForm));
                    }}
                    onItemReplaced={(item) => {
                      subjectForm.treatments.handleInputChangeListReplace(item);
                      setSubjectForm(Form.copy(subjectForm));
                    }}
                    onItemDeleted={(item) => {
                      subjectForm.treatments.handleInputChangeListRemove(item);
                      setSubjectForm(Form.copy(subjectForm));
                    }}
                    treatments={subjectForm.treatments.getUI()}
                    errorText={subjectForm.treatments.error}
                  />
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-header">Account Type:</div>
                <div className="card-body">
                  <AccountTypeRadioButtons
                    accountType={subjectForm.account_type.getUI()}
                    handleSelect={(option) => {
                      subjectForm.account_type.handleInputChange(option);
                      setSubjectForm(Form.copy(subjectForm));
                    }}
                  />

                  {subjectForm.account_type.getUI() === patient_account_types.remote ? 
                <>
                                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <label className="medium mb-1">
                      Enter Patient's phone number for account activation. By entering a phone number, you are opting in to receive SMS messages for account
                      creation and recovery.
                    </label>
                  </div>
                      <PhoneInput
                        country="US"
                        countries={["US"]}
                        defaultCountry="US"
                        addInternationalOption={false}
                        limitMaxLength={true}
                        // international={false}
                        // countryCallingCodeEditable={false}
                        placeholder="Enter phone number"
                        value={subjectForm.phone_number.getUI()}
                        onChange={(value) => {
                          console.log(value);
                          subjectForm.phone_number.handleInputChange(value); // Handling the input as a string
                          setSubjectForm(Form.copy(subjectForm));
                        }}
                        style={{marginBottom: 10}}
                      />
                      {subjectForm.phone_number.error && <div className="tiny alert alert-danger">{subjectForm.phone_number.error}</div>}
                </> : <></>  
                }




                  <div style={{ display: "flex", justifyContent: "flex-end", gap: "30px" }}>
                    <button className="btn btn-primary" type="button" onClick={onAddPressed}>
                      Add Subject
                    </button>
                    <button className="btn btn-red" type="button" onClick={onCancelPressed}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer light={true} />
        </div>
      </div>
      {showError ? (
        <ErrorPopup
          message={errorMessage}
          onClose={() => {
            setShowError(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
