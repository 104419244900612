import Navbar from "../components/NavBars/Navbar";
import React, { useState, useEffect } from "react";
import SideNavbar from "../components/NavBars/SideNavbar";
import Footer from "../components/Footer";
import MainHeader from "../components/Headers/MainHeader";
import Global from "../utils/Global";
import TreatmentSearchableDropdown from "../components/Inputs/TreatmentSearchableDropdown";
import MultiPrimarySearchableDropdown from "../components/Inputs/MultiPrimarySearchableDropdown";
import PrimaryCondition from "../utils/PrimaryCondition";
import sex_options from "../config/sex_options";
import { useNavigate } from "react-router-dom";
import Subject from "../utils/Subject";
import PatchForm from "../utils/Form/PatchForm";
import TreatmentStatusRadioButtons from "../components/Inputs/TreatmentStatusRadioButtons";
import ErrorPopup from "../components/Modals/ErrorPopup";
import Treatment from "../utils/Treatment";
import store from "../utils/Redux/store";
import { updateUserSubjects } from "../utils/Redux/actions";

export default function EditSubject() {
  let navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const onCancelPressed = () => {
    navigate(-1);
  };

  const onAddPressed = async () => {
    if (verifying) {
      return;
    }
    setVerifying(true);

    try {
      const new_subject = Subject.createFromForm(subjectForm);
    } catch (error) {
      setSubjectForm(PatchForm.copy(error.form));
      console.log(error.form);
      setVerifying(false);
      return;
    }

    Global.localAppHandler
    .app_patch_subject(subjectForm)
    .then((updated_subject) => {
      store.dispatch(updateUserSubjects(null));
      navigate(-1);
    })
    .catch((returnStatus) => {
      console.log("CAUGHT ERROR")
      setShowError(true);
    })

    setVerifying(false);
  };

  const active_subject = Global.localAppHandler.active_subject;

  const [subjectForm, setSubjectForm] = useState(null);
  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    if (active_subject) {
      setSubjectForm(Subject.createPatchForm(active_subject))
      console.log(active_subject);
    } else {
      navigate("/dashboard")
    }
  }, [active_subject]);

  useEffect(() => {
    document.body.classList.add("nav-fixed");
    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
      <SideNavbar patientSubHeaderEnabled={false} activeTab={{ studyOverview: false, subjects: true, manageTeam: false, reviewReports: false, remoteAlerts: false }} />
        <div id="layoutSidenav_content">
          <main>
            <MainHeader title="Edit Subject" />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <div className="card mb-4">
                <div className="card-header">Demographic Information</div>
                <div className="card-body">
                  {/* Form Group (username)*/}
                  <div className="mb-3">
                    <label className="small mb-1" htmlFor="inputUsername">
                      Subject ID
                    </label>
                    <input
                      className="form-control"
                      id="inputUsername"
                      type="text"
                      // placeholder="Enter your first name"
                      defaultValue={subjectForm?.name.getUI()}
                      readOnly
                    />
                  </div>
                  {/* Form Row*/}
                  <div className="row gx-3 mb-3">
                    {/* Form Group (first name)*/}
                    <div className="col-md-3">
                      <label className="small mb-1" htmlFor="inputAge">
                        Age (years):
                      </label>
                      <input
                        className="form-control mb-2"
                        id="inputAge"
                        type="number"
                        min={0}
                        max={130}
                        value={isNaN(subjectForm?.age.getUI()) ? "" : subjectForm?.age.getUI()}
                        onKeyPress={(e) => {
                          // Allow only digits and prevent hyphens and other special characters
                          const allowedCharacters = /^[0-9]+$/;
                          if (!allowedCharacters.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          subjectForm?.age.handleInputChange(parseInt(event.target.value));
                          setSubjectForm(PatchForm.copy(subjectForm));
                        }}
                      />
                      {subjectForm?.age.error && <div className="tiny alert alert-danger">{subjectForm.age.error}</div>}
                    </div>
                    {/* Form Group (last name)*/}
                    <div className="col-md-3">
                      <label className="small mb-1" htmlFor="inputHeight">
                        Height (in):
                      </label>
                      <input
                        className="form-control mb-2"
                        id="inputHeight"
                        type="number"
                        min={0}
                        max={108}
                        value={isNaN(subjectForm?.height.getUI()) ? "" : subjectForm?.height.getUI()}
                        onKeyPress={(e) => {
                          // Allow only digits and prevent hyphens and other special characters
                          const allowedCharacters = /^[0-9]+$/;
                          if (!allowedCharacters.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          subjectForm?.height.handleInputChange(parseInt(event.target.value));
                          setSubjectForm(PatchForm.copy(subjectForm));
                        }}
                      />
                      {subjectForm?.height.error && <div className="tiny alert alert-danger">{subjectForm.height.error}</div>}
                    </div>
                    <div className="col-md-3">
                      <label className="small mb-1" htmlFor="inputWeight">
                        Weight (lbs):
                      </label>
                      <input
                        className="form-control mb-2"
                        id="inputWeight"
                        type="number"
                        min={0}
                        max={1400}
                        value={isNaN(subjectForm?.weight.getUI()) ? "" : subjectForm?.weight.getUI()}
                        onKeyPress={(e) => {
                          // Allow only digits and prevent hyphens and other special characters
                          const allowedCharacters = /^[0-9]+$/;
                          if (!allowedCharacters.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          subjectForm?.weight.handleInputChange(parseInt(event.target.value));
                          setSubjectForm(PatchForm.copy(subjectForm));
                        }}
                      />
                      {subjectForm?.weight.error && <div className="tiny alert alert-danger">{subjectForm.weight.error}</div>}
                    </div>
                    <div className="col-md-3 align-items-end">
                      <div className="dropdown mb-2 mt-4">
                        <button
                          className="btn btn-primary dropdown-toggle"
                          id="dropdownFadeInUp"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {subjectForm?.sex.getUI() ? subjectForm.sex.getUI() : "Sex"}
                        </button>
                        <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                          {sex_options.sex.map((option, index) => (
                            <div
                              key={index}
                              className="dropdown-item"
                              onClick={(event) => {
                                subjectForm?.sex.handleInputChange(event.target.innerText);
                                setSubjectForm(PatchForm.copy(subjectForm));
                              }}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      </div>
                      {subjectForm?.sex.error && <div className="tiny alert alert-danger">{subjectForm.sex.error}</div>}
                    </div>
                  </div>

                  {/* Form Row        */}
                  <MultiPrimarySearchableDropdown
                    placeholder={"Select ICD-10-CM Code"}
                    onItemAdded={(item) => {
                      // need this here so that if a code_id exists we are keeping this code_id value and not setting it to null.
                      item = PrimaryCondition.checkIfConditionExists(item, subjectForm?.primary_conditions.value.map(item => item.value))
                      subjectForm?.primary_conditions.handleInputChangeListAdd(item);
                      setSubjectForm(PatchForm.copy(subjectForm));
                    }}
                    onItemReplaced={(item) => {
                      item = PrimaryCondition.checkIfConditionExists(item, subjectForm?.primary_conditions.value.map(item => item.value))
                      subjectForm?.primary_conditions.handleInputChangeListReplace(item);
                      setSubjectForm(PatchForm.copy(subjectForm));
                    }}
                    onItemDeleted={(item) => {
                      item = PrimaryCondition.checkIfConditionExists(item, subjectForm?.primary_conditions.value.map(item => item.value))
                      subjectForm?.primary_conditions.handleInputChangeListRemove(item);
                      setSubjectForm(PatchForm.copy(subjectForm));
                    }}
                    primaryConditions={subjectForm?.primary_conditions.getUI()}
                    errorText={subjectForm?.primary_conditions.error}
                  />
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header">Treatment Status:</div>
                <div className="card-body">
                  <TreatmentStatusRadioButtons
                    treatmentStatus={subjectForm?.treatment_status.getUI()}
                    handleSelect={(option) => {
                      subjectForm?.treatment_status.handleInputChange(option);
                      setSubjectForm(PatchForm.copy(subjectForm));
                    }}
                  />

                  <TreatmentSearchableDropdown
                    placeholder={"Select CPT Code"}
                    onItemAdded={(item) => {
                      // need this here so that if a code_id exists we are keeping this code_id value and not setting it to null.
                      item = Treatment.checkIfTreatmentExists(item, subjectForm?.treatments.value.map(item => item.value))
                      subjectForm?.treatments.handleInputChangeListAdd(item);
                      setSubjectForm(PatchForm.copy(subjectForm));
                    }}
                    onItemReplaced={(item) => {
                      item = Treatment.checkIfTreatmentExists(item, subjectForm?.treatments.value.map(item => item.value))
                      subjectForm?.treatments.handleInputChangeListReplace(item);
                      setSubjectForm(PatchForm.copy(subjectForm));
                    }}
                    onItemDeleted={(item) => {
                      item = Treatment.checkIfTreatmentExists(item, subjectForm?.treatments.value.map(item => item.value))
                      subjectForm?.treatments.handleInputChangeListRemove(item);
                      setSubjectForm(PatchForm.copy(subjectForm));
                    }}
                    treatments={subjectForm?.treatments.getUI()}
                    errorText={subjectForm?.treatments.error}
                  />

                  <div style={{ display: "flex", justifyContent: "flex-end", gap: "30px" }}>
                    <button className="btn btn-primary" type="button" onClick={onAddPressed}>
                      Save Changes
                    </button>
                    <button className="btn btn-red" type="button" onClick={onCancelPressed}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer light={true} />
        </div>
      </div>
      {showError ? <ErrorPopup message={"Error updating subject. Please try again."} onClose={() => {setShowError(false)}} /> : ""}
    </>
  );
}
