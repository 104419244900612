import React, { useState, useEffect, useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { PlusCircle, RefreshCw } from "react-feather";
import { GlobalFilter } from "../GlobalFilter";
import { useNavigate } from "react-router-dom";
import Global from "../../utils/Global";
import Subject from "../../utils/Subject";
import RefreshButton from "../RefreshButton";
import patient_account_types from "../../config/patient_account_types";
import account_status from "../../config/account_status";

export function Table({ subjectsLoading, setSubjectsLoading, subjects, ...props }) {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState(null);

  // console.log('subjects inside Table:', subjects);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const removeUnderscorePrefix = (obj) => {
    if (Array.isArray(obj)) {
      // If it's an array, apply the function to each element
      return obj.map(removeUnderscorePrefix);
    } else if (obj !== null && typeof obj === "object") {
      // If it's an object, process each key
      return Object.keys(obj).reduce((accumulator, currentKey) => {
        const newKey = currentKey.startsWith("_") ? currentKey.substring(1) : currentKey;
        accumulator[newKey] = removeUnderscorePrefix(obj[currentKey]); // Recursive call for nested objects/arrays
        return accumulator;
      }, {});
    } else {
      // If it's neither an array nor an object, return it as is
      return obj;
    }
  };

  const onRowClick = (cell) => {
    // if (cell.column.Header !== "Tags") {
    const row = cell.row.original;
    // console.log(row);
    let newRow = removeUnderscorePrefix(row);

    const subject = Subject.createFromAPI(newRow);
    // console.log(subject);
    Global.localAppHandler
      .app_set_subject(subject)
      .then((subject_id) => {
        navigate("/dashboard/" + subject_id + "/overview");
      })
      .catch((returnStatus) => {
        // console.log("this should never happen");
      });

    // }
  };

  const toggleFilter = (newFilter) => {
    const isCurrentlyActive = activeFilter === newFilter;
    setFilter(isCurrentlyActive ? "" : newFilter); // Clear or apply new filter
    setActiveFilter(isCurrentlyActive ? null : newFilter); // Set active filter for UI feedback
  };

  const initialState = {
    sortBy: [
      {
        id: "_name", // the accessor of the column you want to sort by
        desc: false, // false for ascending, true for descending
      },
    ],
  };

  const patientsData = useMemo(() => {
    if (!filter) return subjects; // No filter applied
    return subjects.filter((subject) => {
      if (filter === "Show Remote Only") {
        return subject._account_type === patient_account_types.remote;
      } else if (filter === "Show In-Clinic Only") {
        return subject._account_type === patient_account_types.inclinic;
      } else if (filter === "Show Active Only") {
        return subject._account_status === account_status.active;
      } else {
        return false;
      }
    });
  }, [subjects, filter]);
  const patientsColumns = useMemo(
    () =>
      subjects && subjects[0]
        ? [
            {
              Header: "Subject ID",
              accessor: "_name",
            },
            {
              Header: "Primary Condition(s)",
              accessor: (row) => (row._primary_conditions[0] ? row._primary_conditions[0]._description : ""),
              id: "_primary_conditions",
            },
            {
              Header: "Account Type",
              accessor: "_account_type",
            },
            {
              Header: "Account Status",
              accessor: "_account_status",
            },
            // {
            //   Header: "Last Active",
            //   accessor: "last_active",
            // },
          ]
        : [],
    [subjects]
  );

  const tableInstance = useTable(
    {
      columns: patientsColumns,
      data: patientsData,
      initialState, // include initialState here
      getTrProps: onRowClick,
    },
    useGlobalFilter,
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state } = tableInstance;

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (subjects && subjects.length === 0) {
  //   return <div>No Subjects registered.</div>;
  // }

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
        {/* Plus button */}

        <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />

        <div style={{ display: "flex", alignItems: "center" }}>
          {["Show Remote Only", "Show In-Clinic Only", "Show Active Only"].map((status, index, array) => (
            <React.Fragment key={status}>
              <button
                style={{
                  background: "none",
                  color: activeFilter === status ? "blue" : "gray",
                  cursor: "pointer",
                  border: "none",
                  textDecoration: activeFilter === status ? "underline" : "",
                  padding: "0 10px",
                }}
                onClick={() => toggleFilter(status)}
              >
                {status}
              </button>
              {index < array.length - 1 && <span style={{ margin: "0 5px" }}>/</span>}
            </React.Fragment>
          ))}
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            className="btn btn-primary me-2"
            onClick={() => {
              navigate("/addSubject"); // Replace "new-page" with the desired URL of the new page
            }}
          >
            <PlusCircle size={16} style={{ marginRight: "5px" }} />
            Add Subject
          </button>

          <RefreshButton subjectsLoading={subjectsLoading} setSubjectsLoading={setSubjectsLoading} />
        </div>
      </div>
      <table className="table table-bordered" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, headerGroupIdx) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIdx}>
              {headerGroup.headers.map((column, columnIdx) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIdx}>
                  {column.render("Header")}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <img src={require("../../images/down_arrow.png")} alt="" />
                    ) : (
                      <img src={require("../../images/up_arrow.png")} alt="" />
                    )
                  ) : column.Header === "Actions" ? (
                    ""
                  ) : (
                    <img src={require("../../images/default.png")} alt="" />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {subjectsLoading ? (
            <tr>
              <td colSpan={patientsColumns.length}>Loading...</td>
            </tr>
          ) : rows.length > 0 ? (
            rows.map((row, rowIdx) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={rowIdx}
                  onMouseEnter={() => setHoveredRowIndex(rowIdx)} // Set hovered row index on mouse enter
                  onMouseLeave={() => setHoveredRowIndex(null)} // Clear hovered row index on mouse leave
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {row.cells.map((cell, cellIdx) => (
                    <td
                      key={cellIdx}
                      className={hoveredRowIndex === rowIdx ? "link-primary" : ""}
                      {...cell.getCellProps()}
                      onClick={() => {
                        onRowClick(cell);
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={patientsColumns.length}>No Subjects registered.</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
