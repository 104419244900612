import React, { useState, useEffect, useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { Download } from "react-feather";
import { GlobalFilter } from "../GlobalFilter";
import { useNavigate } from "react-router-dom";
import Global from "../../utils/Global";

export function TrialTable({ trials, isLoading, setSelectedRowName, setSelectedRowId, allowTwoSelections, ...props }) {
  const navigate = useNavigate();
  console.log("TRIALS")
  console.log(trials);

  const onRowClick = (row) => {
    // console.log(row);
    // if (setSelectedRowName) {
    //   console.log(row.index);
    //   setSelectedRowName(row.values.description);
    // }
    // if (setSelectedRowId) {
    //   console.log(row.index);
    //   setSelectedRowId(row.values.trial_id);
    // }

    // Global.localAppHandler.app_set_subject(row.subject_id).then((subject_id) => {
    const newPath = window.location.pathname.slice(0, window.location.pathname.lastIndexOf("/")) + "/trials/" + trials[row.index].trial_id    
    
    Global.localAppHandler.app_set_active_trial(trials[row.index].trial_id).then((trial_id) => {
      navigate(newPath);
    }).catch((returnStatus) => {
      console.log("this should never happen");
    })
    


    // }).catch((returnStatus) => {
    //   // console.log("this should never happen");
    // })
  };

  const patientsData = useMemo(() => [...trials], [trials]);

  const patientsColumns = useMemo(
    () =>
      trials[0]
        ? Object.keys(trials[0])
            .filter((key) => key === "_trial_name" || key === "_trial_date")
            .map((key) => {
              var title = key;
              if (key === "_trial_name") {
                title = "Trial Name";
              } else if (key === "_trial_date") {
                title = "Trial Date";
                return {
                  Header: title,
                  accessor: key,
                  Cell: ({ value }) => {
                    // Convert value from UTC to local date
                    const date = new Date(value);
                    return date.toLocaleString(); // You can also use toLocaleDateString() or toLocaleTimeString() to customize the format
                  },
                };
              }
              return { Header: title, accessor: key };
            })
        : [],
    [trials]
  );

  // const tableHooks = (hooks) => {
  //   hooks.visibleColumns.push((columns) => [
  //     ...columns,
  //     {
  //       id: "duration",
  //       Header: "Duration",
  //       Cell: ({ row }) => <>{row.id}</>,
  //     },
  //     {
  //       id: "actions",
  //       Header: "Actions",
  //       Cell: ({ row }) => (
  //         <>
  //           <button
  //             onClick={() => {
  //               let userId = "51b9";
  //               // console.log(selectedRowId);
  //               let trialId = row.original.trial_id;
  //               console.log(row.original.trial_id);
  //               // console.log(selectedRowName);
  //               let idToken = "test";
  //               fetch("http://Apitesting1-env.eba-empcbmf2.us-east-1.elasticbeanstalk.com/user/" + userId + "/trial/id/" + trialId, {
  //                 method: "GET",
  //                 headers: {
  //                   Accept: "application/json",
  //                   "Content-Type": "application/json",
  //                   IdToken: idToken,
  //                 },
  //               })
  //                 .then((data) => data.json())
  //                 .then((result) => {
  //                   console.log(result);
  //                   const fileData = JSON.stringify(result);
  //                   const blob = new Blob([fileData], { type: "text/plain" });
  //                   const url = URL.createObjectURL(blob);
  //                   const link = document.createElement("a");
  //                   link.download = row.original.description + ".json";
  //                   link.href = url;
  //                   link.click();
  //                 })
  //                 .catch((error) => {
  //                   console.error("Error:", error);
  //                 });
  //             }}
  //             className="btn btn-datatable btn-icon btn-white me-2"
  //           >
  //             <Download size={15} />
  //           </button>
  //           {/* <button className="btn btn-datatable btn-icon btn-white">
  //             <Trash2 size={15} />
  //           </button> */}
  //         </>
  //       ),
  //     },
  //   ]);
  // };

  const tableInstance = useTable(
    { columns: patientsColumns, data: patientsData, initialState: { hiddenColumns: ["trial_id"] }, getTrProps: onRowClick },
    useGlobalFilter,
    // tableHooks,
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state } = tableInstance;
  useEffect(() => {
    if (rows && rows[0] && setSelectedRowName) {
      setSelectedRowName(rows[0].values.trial_name);
    }
    if (rows && rows[0] && setSelectedRowId) {
      setSelectedRowId(rows[0].values.trial_id);
    }
  }, [rows, setSelectedRowName, setSelectedRowId]);

  return (
    <>
      <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />
      <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>

      <table className="table table-bordered" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <img src={require("../../images/down_arrow.png")} alt="" />
                    ) : (
                      <img src={require("../../images/up_arrow.png")} alt="" />
                    )
                  ) : column.Header === "Actions" ? (
                    ""
                  ) : (
                    <img src={require("../../images/default.png")} alt="" />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading ? (
            <tr><td colSpan={patientsColumns.length}>Loading...</td></tr>
          ) : rows.length > 0 ? (
            rows.map((row, idx) => {
              prepareRow(row);
              return (
                <tr
                  className={"link-primary"}
                  {...row.getRowProps()}
                  onClick={() => {
                    onRowClick(row);
                  }}
                >
                  {row.cells.map((cell, idx) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })
          ) : (
            <tr><td colSpan={patientsColumns.length}>No Trials Recorded.</td></tr>
          )}
        </tbody>
      </table>
      </div>
    </>
  );
}
