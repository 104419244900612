import Navbar from "../components/NavBars/Navbar";
import React, { useEffect } from "react";
import SideNavbar from "../components/NavBars/SideNavbar";
import SettingsNavbar from "../components/NavBars/SettingsNavbar";
import Footer from "../components/Footer";
import SettingsHeader from "../components/Headers/SettingsHeader";
import moment from "moment";

export default function Billing() {
  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
      <SideNavbar patientSubHeaderEnabled={false} activeTab={{ studyOverview: false, subjects: false, manageTeam: false, reviewReports: false, remoteAlerts: false }} />

        <div id="layoutSidenav_content">
          <main>
            <SettingsHeader title="Account Settings - Billing" />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              {/* Account page navigation*/}
              <SettingsNavbar activeTab={{ account: false, billing: true, security: false }} />
              <hr className="mt-0 mb-4" />
              <div className="row justify-content-md-center">
                <div className="col-lg-8 mb-4">
                  {/* Billing card 1*/}
                  <div className="card h-100 border-start-lg border-start-primary">
                    <div className="card-body">
                      <div className="small text-muted">Current monthly bill</div>
                      <div className="h3">
                        {"$30.00 x " +
                          JSON.parse(String(window.localStorage.getItem("patientList"))).length +
                          " = ~$" +
                          30 * JSON.parse(String(window.localStorage.getItem("patientList"))).length}
                      </div>
                      <div className="small text-muted">
                        {"Approximate cost generated based on " +
                          JSON.parse(String(window.localStorage.getItem("patientList"))).length +
                          " patients billed at $30/month."}
                      </div>
                      <hr />
                      <div className="small text-muted">
                        This may not reflect prorated fees if a patient was added mid billing cycle. For an exact cost please check the monthly invoice sent to
                        your email.
                      </div>
                      {/* <a className="text-arrow-icon small" href="#!">
                        Switch to yearly billing
                        <i data-feather="arrow-right" />
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-lg-8 mb-4">
                  {/* Billing card 2*/}
                  <div className="card h-100 border-start-lg border-start-secondary">
                    <div className="card-body">
                      <div className="small text-muted">Next payment due</div>
                      <div className="h3">{Number(moment().format("DD")) >= 2 ? moment().add(1, "month").format("MMMM") + " 1" : moment().format("MMMM") + " 1"}</div>
                      {/* <a className="text-arrow-icon small text-secondary" href="#!">
                        View payment history
                        <i data-feather="arrow-right" />
                      </a> */}
                      <hr />
                      <div className="small text-muted">
                        Invoice sent to email on:{" "}
                        {Number(moment().format("DD")) >= 2 ? moment().format("MMMM") + " 2" : moment().subtract(1, "month").format("MMMM") + " 2"}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4 mb-4"> */}
                {/* Billing card 3*/}
                {/* <div className="card h-100 border-start-lg border-start-success">
                    <div className="card-body">
                      <div className="small text-muted">Current plan</div>
                      <div className="h3 d-flex align-items-center">Freelancer</div>
                      <a className="text-arrow-icon small text-success" href="#!">
                        Upgrade plan
                        <i data-feather="arrow-right" />
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* Payment methods card*/}
              {/* <div className="card card-header-actions mb-4">
                <div className="card-header">
                  Payment Methods
                  <button className="btn btn-sm btn-primary" type="button">
                    Add Payment Method
                  </button>
                </div>
                <div className="card-body px-0"> */}
              {/* Payment method 1*/}
              {/* <div className="d-flex align-items-center justify-content-between px-4">
                    <div className="d-flex align-items-center">
                      <i className="fab fa-cc-visa fa-2x cc-color-visa" />
                      <div className="ms-4">
                        <div className="small">Visa ending in 1234</div>
                        <div className="text-xs text-muted">Expires 04/2024</div>
                      </div>
                    </div>
                    <div className="ms-4 small">
                      <div className="badge bg-light text-dark me-3">Default</div>
                      <a href="#!">Edit</a>
                    </div>
                  </div>
                  <hr /> */}
              {/* Payment method 2*/}
              {/* <div className="d-flex align-items-center justify-content-between px-4">
                    <div className="d-flex align-items-center">
                      <i className="fab fa-cc-mastercard fa-2x cc-color-mastercard" />
                      <div className="ms-4">
                        <div className="small">Mastercard ending in 5678</div>
                        <div className="text-xs text-muted">Expires 05/2022</div>
                      </div>
                    </div>
                    <div className="ms-4 small">
                      <a className="text-muted me-3" href="#!">
                        Make Default
                      </a>
                      <a href="#!">Edit</a>
                    </div>
                  </div>
                  <hr /> */}
              {/* Payment method 3*/}
              {/* <div className="d-flex align-items-center justify-content-between px-4">
                    <div className="d-flex align-items-center">
                      <i className="fab fa-cc-amex fa-2x cc-color-amex" />
                      <div className="ms-4">
                        <div className="small">American Express ending in 9012</div>
                        <div className="text-xs text-muted">Expires 01/2026</div>
                      </div>
                    </div>
                    <div className="ms-4 small">
                      <a className="text-muted me-3" href="#!">
                        Make Default
                      </a>
                      <a href="#!">Edit</a>
                    </div>
                  </div> */}
              {/* </div>
              </div> */}
              {/* Billing history card*/}
              {/* <div className="card mb-4">
                <div className="card-header">Billing History</div>
                <div className="card-body p-0"> */}
              {/* Billing history table*/}
              {/* <div className="table-responsive table-billing-history">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th className="border-gray-200" scope="col">
                            Transaction ID
                          </th>
                          <th className="border-gray-200" scope="col">
                            Date
                          </th>
                          <th className="border-gray-200" scope="col">
                            Amount
                          </th>
                          <th className="border-gray-200" scope="col">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>#39201</td>
                          <td>06/15/2021</td>
                          <td>$29.99</td>
                          <td>
                            <span className="badge bg-light text-dark">Pending</span>
                          </td>
                        </tr>
                        <tr>
                          <td>#38594</td>
                          <td>05/15/2021</td>
                          <td>$29.99</td>
                          <td>
                            <span className="badge bg-success">Paid</span>
                          </td>
                        </tr>
                        <tr>
                          <td>#38223</td>
                          <td>04/15/2021</td>
                          <td>$29.99</td>
                          <td>
                            <span className="badge bg-success">Paid</span>
                          </td>
                        </tr>
                        <tr>
                          <td>#38125</td>
                          <td>03/15/2021</td>
                          <td>$29.99</td>
                          <td>
                            <span className="badge bg-success">Paid</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
              {/* </div>
              </div> */}
            </div>
          </main>
          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
