import Navbar from "../components/NavBars/Navbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SideNavbar from "../components/NavBars/SideNavbar";
import { TrialTable } from "../components/Tables/TrialTable";
import Footer from "../components/Footer";
import PatientHeader from "../components/Headers/PatientHeader";
import PatientNavbar from "../components/NavBars/PatientNavbar";
import "chartjs-adapter-date-fns";
import Global from "../utils/Global";
import { SubjectDemographicsTable } from "../components/Tables/SubjectDemographicsTable";
import { FiEdit2, FiAlertTriangle } from "react-icons/fi";
import { BsGraphDownArrow } from "react-icons/bs";
import StanceTimeBarGraph from "../components/Graphs/StanceTimeBarGraph";
import SwingASIGraph from "../components/Graphs/SwingASIGraph";
import StanceASIGraph from "../components/Graphs/StanceASIGraph";
import { RemoteCollectionStatusTable } from "../components/Tables/RemoteCollectionStatusTable";
import { AccountSettingsTable } from "../components/Tables/AccountSettingsTable";
import patient_account_types from "../config/patient_account_types";
import account_status from "../config/account_status";
import Subject from "../utils/Subject";

// Chart.register(chartDataLabels);
export default function ManageSubject() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRowName, setSelectedRowName] = useState("");
  const [selectedRowId, setSelectedRowId] = useState("");
  const [subject, setSubject] = useState(null);
  const [subjectForm, setSubjectForm] = useState(null);
  const [periodInformation, setPeriodInformation] = useState(null);
  const [retrieveDataFailed, setRetrieveDataFailed] = useState(false);
  const [trials, setTrials] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [sentConfirmationCode, setSentConfirmationCode] = useState(false);
  const [successfullySentConfirmationCode, setSuccessfullySentConfirmationCode] = useState(null);

  let navigate = useNavigate();

  // useEffect(() => {
  //   // console.log("IS APP GET SUBJECT BEING CALLED AGAIN")
  //   Global.localAppHandler
  //     .app_get_subject()
  //     .then((new_subject) => {
  //       setSubject(new_subject);
  //       setIsLoading(false);
  //     })
  //     .then(() => {
  //       Global.localAppHandler.app_get_trial_list().then((trialList) => {
  //         setTrials(trialList);
  //       });
  //     })
  //     .catch((returnStatus) => {
  //       navigate("/dashboard");
  //     });
  // }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const subject = await Global.localAppHandler.app_get_subject();
        const periodInformation = await Global.localAppHandler.app_retrieve_period_information();
        console.log(periodInformation);
        setSubject(subject);
        setSubjectForm(Subject.createPatchForm(subject));
        setPeriodInformation(periodInformation);

        setRetrieveDataFailed(false);
      } catch (error) {
        setPeriodInformation(null);
        setSubject(null);
        setRetrieveDataFailed(true);
        navigate("/dashboard");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [navigate, refreshData]);

  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  const handleResendConfirmationCode = () => {
    if (sentConfirmationCode) {
      return;
    }
    setSentConfirmationCode(true);
    Global.localAppHandler
      .app_resend_confirmation_code()
      .then((returnStatus) => {
        setSuccessfullySentConfirmationCode(true);
      })
      .catch((err) => {
        setSuccessfullySentConfirmationCode(false);
        console.log(err);
      });
  };

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
        <SideNavbar
          patientSubHeaderEnabled={true}
          activeTab={{ studyOverview: false, subjects: true, manageTeam: false, reviewReports: false, remoteAlerts: false }}
        />
        <div id="layoutSidenav_content">
          <main>
            <PatientHeader bg={"bg-white"} subjectName={subject?.name} />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <PatientNavbar activeTab={{ overview: true, trials: false, detailed: false, gait_metrics: false, reports: false }} />
              {subject?.account_type === patient_account_types.remote && subject?.account_status === account_status.pending ? (
                <div className="alert alert-primary mt-4">
                  {sentConfirmationCode ? (
                        successfullySentConfirmationCode === null ? "Sending..." : successfullySentConfirmationCode ? "Confirmation code resent!" : "Error sending confirmation code. Refresh page and try again."
                  ) : (
                    <>
                      <FiAlertTriangle className="mb-1" style={{ marginRight: "10px" }} />
                      {"Patient has not yet redeemed account. Pending for " + subject?.days_pending + " days. Make sure contact phone is correct."}
                      <span
                        className="link-primary"
                        onClick={handleResendConfirmationCode} // Replace with your function to handle the click
                        style={{ textDecoration: "underline", marginLeft: "5px", cursor: "pointer" }}
                      >
                        Resend Confirmation Code
                      </span>
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className="card mb-4">
                <div className="card-body">
                  <RemoteCollectionStatusTable subject={subject} period={periodInformation} isLoading={isLoading} />
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <AccountSettingsTable subject={subject} isLoading={isLoading} setRefreshData={setRefreshData} />
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <div className="card-header" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="d-flex align-items-center justify-content-between">Subject Details</div>
                    <button
                      onClick={() => {
                        const newPath = window.location.pathname.slice(0, window.location.pathname.lastIndexOf("/")) + "/edit";
                        navigate(newPath);
                      }}
                      disabled={subject ? false : true} // Disable the button based on the boolean
                      className={`btn btn-primary ${subject ? "" : "btn-disabled"}`} // Add 'btn-disabled' class if not editable
                    >
                      Edit <FiEdit2 className="mb-1" style={{ marginLeft: "10px" }} />
                    </button>
                  </div>
                  <SubjectDemographicsTable subject={subject} />
                </div>
              </div>

              {/* <div className="card mb-4">
                <div className="card-body">
                  <TrialTable
                    trials={trials ? trials : []}
                    allowTwoSelections={false}
                    setSelectedRowName={setSelectedRowName}
                    setSelectedRowId={setSelectedRowId}
                    isLoading={isLoading}
                  />
                </div>
              </div> */}
              {/* <div className="card mb-4">
              <div className="d-flex card-header align-items-center justify-content-between">FAOS Scores</div>
                <div className="card-body">
                  <PROTable
                    values={[
                      { faos: 98, symptoms: 100, pain: 96, daily: 56, sports: 100, qol: 88, date: "11/23/2023" },
                      { faos: 98, symptoms: 100, pain: 96, daily: 56, sports: 100, qol: 88, date: "11/23/2023" },
                      { faos: 98, symptoms: 100, pain: 96, daily: 56, sports: 100, qol: 88, date: "11/23/2023" },
                    ]}
                  />
                </div>
              </div> */}
            </div>
          </main>

          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
