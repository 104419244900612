import Navbar from "../components/NavBars/Navbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SideNavbar from "../components/NavBars/SideNavbar";
import Footer from "../components/Footer";
import PatientHeader from "../components/Headers/PatientHeader";
import PatientNavbar from "../components/NavBars/PatientNavbar";
import "chartjs-adapter-date-fns";
import Global from "../utils/Global";
import { ReportsByPeriodTable } from "../components/Tables/ReportsByPeriodTable";
import { TrialTable } from "../components/Tables/TrialTable";

// Chart.register(chartDataLabels);
export default function Reports() {
  const [isLoading, setIsLoading] = useState(true);
  const [subject, setSubject] = useState(null);
  const [periodInformation, setPeriodInformation] = useState(null);

  const [selectedRowName, setSelectedRowName] = useState("");
  const [selectedRowId, setSelectedRowId] = useState("");
  const [trials, setTrials] = useState(null);

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const subject = await Global.localAppHandler.app_get_subject();
        const periodInformation = await Global.localAppHandler.app_retrieve_period_information({ include_all_periods: true });
        const trials = await Global.localAppHandler.app_get_trial_list();
        // Process and set state as necessary

        // console.log("PERIOD INFORMATION");
        // console.log(periodInformation);

        // const periodInformation = [{start_date: "01/01/2020", end_date: "02/01/2020", period_length: 30, days_collected: 20},
        // {start_date: "02/01/2020", end_date: "03/01/2020", period_length: 30, days_collected: 10},
        // {start_date: "01/01/2020", end_date: "02/01/2020", period_length: 30, days_collected: 20},
        // {start_date: "01/01/2020", end_date: "02/01/2020", period_length: 30, days_collected: 20},
        // {start_date: "01/01/2020", end_date: "02/01/2020", period_length: 30, days_collected: 20},
        // {start_date: "01/01/2020", end_date: "02/01/2020", period_length: 30, days_collected: 20}
        // ]

        setSubject(subject);
        setPeriodInformation(periodInformation);
        setTrials(trials);
      } catch (error) {
        navigate("/dashboard");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
        <SideNavbar
          patientSubHeaderEnabled={true}
          activeTab={{ studyOverview: false, subjects: true, manageTeam: false, reviewReports: false, remoteAlerts: false }}
        />
        <div id="layoutSidenav_content">
          <main>
            <PatientHeader bg={"bg-white"} subjectName={subject ? subject.name : ""} />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <PatientNavbar activeTab={{ overview: false, trials: false, detailed: false, gait_metrics: false, reports: true }} />

              <div className="card mb-4">
                <div className="card-body">
                  <TrialTable
                    trials={trials ? trials : []}
                    allowTwoSelections={false}
                    setSelectedRowName={setSelectedRowName}
                    setSelectedRowId={setSelectedRowId}
                    isLoading={isLoading}
                  />
                </div>
              </div>

              {/* <ReportsByPeriodTable periods={periodInformation}/> */}
            </div>
          </main>

          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
