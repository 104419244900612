import Navbar from "../components/NavBars/Navbar";
import React, { useState, useEffect } from "react";
import SideNavbar from "../components/NavBars/SideNavbar";
import { Table } from "../components/Tables/Table";
import Footer from "../components/Footer";
import MainHeader from "../components/Headers/MainHeader";
import Global from "../utils/Global";
import { useSelector } from "react-redux";
import store from "../utils/Redux/store";
import { updateUserSubjects } from "../utils/Redux/actions";

export default function Dashboard() {
  // const [subjects, setSubjects] = useState([]);
  const [subjectsLoading, setSubjectsLoading] = useState(true);

  const subjects = useSelector((state) => state.subjectList);

  useEffect(() => {
    document.body.classList.add("nav-fixed");
    let isMounted = true;

    setSubjectsLoading(true);

    // Try to get the cached data from session storage
    // const cachedSubjects = sessionStorage.getItem("userSubjects");
    if (subjectsLoading) {
      if (subjects) {
        // Parse and use the cached data if it exists
        // setSubjects(JSON.parse(cachedSubjects));
        setSubjectsLoading(false);
      } else {
        // Fetch from API if no cached data is available
        Global.localAppHandler
          .app_get_subject_list({include_conditions: true, include_treatments: true, include_code_desc: true})
          .then((subjectList) => {
            if (isMounted) {
              console.log(subjectList)
              // Cache the fetched data in session storage
              // console.log("dispatching user subjects")
              // store.dispatch(updateUserSubjects(subjectList))
              store.dispatch(updateUserSubjects(JSON.stringify(subjectList)))
              // sessionStorage.setItem("userSubjects", JSON.stringify(user.subject_summary));
              // setSubjects(user.subject_summary);
              setSubjectsLoading(false);
            }
          })
          .catch((returnStatus) => {
            if (isMounted) {
              console.log("get subject list failed")
              store.dispatch(updateUserSubjects(null))
              setSubjectsLoading(false);
            }
          });
      }
    }


    return () => {
      isMounted = false;
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
      <SideNavbar patientSubHeaderEnabled={false} activeTab={{ studyOverview: false, subjects: true, manageTeam: false, reviewReports: false, remoteAlerts: false }} />
        <div id="layoutSidenav_content">
          <main>
            <MainHeader title="Subjects" />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <div className="card mb-4">
                {/*.card-header Patients*/}
                <div className="card-body">
                  <Table subjectsLoading={subjectsLoading} setSubjectsLoading={setSubjectsLoading} subjects={subjects}/>
                </div>
              </div>
            </div>
          </main>
          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
