export function dateValidator(date) {
  // Check if the date is entered
  if (!date) return "Enter date";

  // Check the length of the input to match MM-DD-YYYY
  if (date.length !== 10) return "Date must be in MM-DD-YYYY format";

  // Manually parse the date components
  const [month, day, year] = date.split('-').map(Number);

  // Check for valid month, day, year values
  if (month < 1 || month > 12) return "Invalid month";
  if (day < 1 || day > 31) return "Invalid day";
  if (year < 1000 || year > new Date().getFullYear()) return "Invalid year";

  // Create a date object using the components
  const inputDate = new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date

  // Check if the date is valid and in the past
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Normalize current date to avoid time-related issues

  // Ensure the input date didn't roll over to the next month or day
  if (inputDate.getDate() !== day || inputDate.getMonth() + 1 !== month || inputDate.getFullYear() !== year) {
    return "Invalid date";
  }

  if (isNaN(inputDate.getTime())) {
    return "Invalid date";
  }

  if (inputDate >= currentDate) {
    return "Date must be in the past";
  }

  return ""; // No error
}


