import Navbar from "../components/NavBars/Navbar";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import SideNavbar from "../components/NavBars/SideNavbar";
import Footer from "../components/Footer";
import PatientHeader from "../components/Headers/PatientHeader";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import Global from "../utils/Global";
import { IoMdArrowRoundBack } from "react-icons/io";
import { TrialInformationTable } from "../components/Tables/TrialInformationTable";
import dataLabelPlugin from 'chartjs-plugin-datalabels';
import { MetricsTable } from "../components/Tables/MetricsTable";

Chart.register(dataLabelPlugin)

export default function ChartTrials() {
  const [dataReady, setDataReady] = useState(true);
  const [subject, setSubject] = useState(null);
  const [trial, setTrial] = useState(null);


  let navigate = useNavigate();

  let { providerCode, patientId, patientName } = useParams();

  useEffect(() => {
    setSubject(Global.localAppHandler.app_get_active_subject());
    // TODO: this will likely merge into 1 call based on API structure, don't really need to call app_get subject again probably.
    Global.localAppHandler
      .app_get_trial({include_metrics: true})
      .then((new_trial) => {
        setTrial(new_trial);
        setDataReady(true);
      })
      .catch((returnStatus) => {
        navigate(-1);
      });
  }, [navigate]);

  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return !dataReady ? null : (
    <>
      <Navbar />
      <div id="layoutSidenav">
        <SideNavbar patientSubHeaderEnabled={true}  activeTab={{ studyOverview: false, subjects: true, manageTeam: false, reviewReports: false, remoteAlerts: false }} />
        <div id="layoutSidenav_content">
          <main>
            <IoMdArrowRoundBack className="m-4" size={30} onClick={() => navigate(-1)} />
            <div className="d-flex justify-content-between align-items-center mx-12 mb-5">
              <PatientHeader subjectName={subject ? subject.name : ""} />
            </div>
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">

            <div className="card mb-4">
                {/*.card-header Patients*/}
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                  <h4>{trial ? trial.trial_name : ""}</h4>
                  </div>

                  <TrialInformationTable trial={trial}/>
                </div>
              </div>

              <div className="card mb-4">
                {/*.card-header Patients*/}
                <div className="card-body">
                  <MetricsTable metrics={trial && trial.trial_metrics ? trial.trial_metrics : null}/>
                </div>
              </div>
            </div>
          </main>

          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
