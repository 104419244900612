import Navbar from "../components/NavBars/Navbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SideNavbar from "../components/NavBars/SideNavbar";
import Footer from "../components/Footer";
import PatientHeader from "../components/Headers/PatientHeader";
import SexDemographicsPieChart from "../components/Graphs/SexDemographicsPieChart";
import AgeDemographicsBarChart from "../components/Graphs/AgeDemographicsBarChart";
import TreatmentDemographics from "../components/Graphs/TreatmentDemographics";
import Global from "../utils/Global";
import { useSelector } from "react-redux";
import store from "../utils/Redux/store";
import { updateUserSubjects } from "../utils/Redux/actions";
import RefreshButton from "../components/RefreshButton";

export default function StudyOverview() {
  let navigate = useNavigate();
  const [subjectSummary, setSubjectSummary] = useState(null);
  const [subjectsLoading, setSubjectsLoading] = useState(true);
  const subjects = useSelector((state) => state.subjectList);

  useEffect(() => {
    console.log(subjects);
    if (subjects) {
      let treatmentsObject = {};
      let m = 0;
      let f = 0;
      let x = 0;
      let age = [];
      subjects.forEach((subject) => {
        if (subject._sex == "F") {
          f++;
        } else if (subject._sex == "M") {
          m++;
        } else if (subject._sex == "X") {
          x++;
        }
        age.push(subject._age);
        if (subject._treatments) {
          subject._treatments.forEach((treatment) => {
            if (treatmentsObject[treatment._code_value]) {
              treatmentsObject[treatment._code_value].count++;
            } else {
              treatmentsObject[treatment._code_value] = { count: 1, description: treatment._description };
            }
          });
        }
      });
      setSubjectSummary({
        len: subjects.length,
        treatments: treatmentsObject,
        sex: { M: m, F: f, X: x },
        age: age,
      });
    }
  }, [subjects]);

  useEffect(() => {
    document.body.classList.add("nav-fixed");
    let isMounted = true;

    // Try to get the cached data from session storage
    // const cachedSubjects = sessionStorage.getItem("userSubjects");
    setSubjectsLoading(true);
    if (subjectsLoading) {
      if (subjects) {
        // Parse and use the cached data if it exists
        // setSubjects(JSON.parse(cachedSubjects));
        setSubjectsLoading(false);
      } else {
        // Fetch from API if no cached data is available
        Global.localAppHandler
          .app_get_subject_list({include_conditions: true, include_treatments: true, include_code_desc: true})
          .then((subjectList) => {
            if (isMounted) {
              store.dispatch(updateUserSubjects(JSON.stringify(subjectList)));
              setSubjectsLoading(false);
            }
          })
          .catch((returnStatus) => {
            if (isMounted) {
              store.dispatch(updateUserSubjects(null));
              setSubjectsLoading(false);
            }
          });
      }
    }

    return () => {
      isMounted = false;
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
        <SideNavbar patientSubHeaderEnabled={false}  activeTab={{ studyOverview: true, subjects: false, manageTeam: false, reviewReports: false, remoteAlerts: false }} />
        <div id="layoutSidenav_content">
          <main>
            {/* <MainHeader title={"Study Overview"}/> */}
            <PatientHeader subjectName={"Study Overview"} />
            {/* Main page content*/}
            {subjectSummary ? (
              <div className="container-xl px-4 mt-n10">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row justify-content-between align-items-center">
                      {/* Column for text entries */}
                      <div className="col">
                        <h3>
                          <b>General: </b>
                        </h3>
                        <h5>{"Current Enrollment: " + subjectSummary.len + " Subjects"}</h5>
                      </div>

                      {/* Column for the RefreshButton */}
                      <div className="col-auto">
                        <RefreshButton subjectsLoading={subjectsLoading} setSubjectsLoading={setSubjectsLoading} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overviewChartBox">
                  <div className="row">
                    <div className="col-lg-3">
                      <SexDemographicsPieChart data={subjectSummary.sex} />
                    </div>
                    <div className="col-lg-9">
                      <AgeDemographicsBarChart ageData={subjectSummary.age} lastUpdatedTime={""} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <TreatmentDemographics data={subjectSummary.treatments} lastUpdatedTime={""} timeAxis={"milliseconds"} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container-xl px-4 mt-n10">
                <div className="card mb-4">
                  <div className="card-body">
                    <h3>
                      <b>Loading Subjects... </b>
                    </h3>
                  </div>
                </div>
              </div>
            )}
          </main>

          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
