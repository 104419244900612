import Navbar from "../components/NavBars/Navbar";
import React, { useEffect, useState } from "react";
import SideNavbar from "../components/NavBars/SideNavbar";
import { ManageTable } from "../components/Tables/ManageTable";
import Footer from "../components/Footer";
import MainHeader from "../components/Headers/MainHeader";
import Global from "../utils/Global";
import { useNavigate } from "react-router";

export default function ManageTeam() {
  let navigate = useNavigate();
  const [team, setTeam] = useState(null)
  const [loading, setLoading] = useState(true)

  const refreshTeam = () => {
    Global.localAppHandler
      .app_get_user_list()
      .then((returnedTeam) => {
        setTeam(returnedTeam)
      })
      .catch((returnStatus) => {
        navigate(-1);
      }).finally(() => {
        setLoading(false)
      })
  }


  useEffect(() => {
    // console.log("IS APP GET SUBJECT BEING CALLED AGAIN")
      refreshTeam();
  }, [navigate]);

  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
      <SideNavbar patientSubHeaderEnabled={false} activeTab={{ studyOverview: false, subjects: false, manageTeam: true, reviewReports: false, remoteAlerts: false }} />
        <div id="layoutSidenav_content">
          <main>
            <MainHeader title="Manage Team" />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <div className="card mb-4">
                <div className="card-header">Team</div>
                <div className="card-body">
                  <ManageTable team={team} isLoading={loading} refreshTeam={refreshTeam} />
                </div>
              </div>
            </div>
          </main>
          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
