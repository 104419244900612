import React, { useState, useRef, useEffect, useMemo } from "react";

import { Bar } from "react-chartjs-2";
import { tooltipOptions, generalOptions, axesOptions, zoomOptions, layoutOptions } from "../../assets/chart-defaults";
import Chart, { ChartData, ChartOptions } from "chart.js/auto";

export default function AgeDemographicsBarChart({ ageData, lastUpdatedTime, ...props }) {
  const stepRef = useRef<Chart<'bar'>>(null);
  const [lastUpdatedTimeNew, setLastUpdatedTimeNew] = useState<string>(lastUpdatedTime);

  const groupAgesIntoIntervals = (data) => {
    // Initialize an array with 10 zeros (one for each decade up to 100)
    const ageGroups = Array(10).fill(0);
    
    data.forEach(age => {
      // Find which decade this age belongs to and increment that decade's count
      const index = Math.min(Math.floor(age / 10), 9); 
      ageGroups[index]++;
    });
    
    return ageGroups;
  };

  const placeholder_stepData = useMemo<ChartData<'bar'>>(
    () => ({
      labels: ["0-9", "10-19", "20-29", "30-39", "40-49", "50-59", "60-69", "70-79", "80-89", "90-100"],
      datasets: [
        {
          label: "Count",
          backgroundColor: "rgba(0,97,242,1)",
          hoverBackgroundColor: "rgba(0,97,242,0.9)",
          borderColor: "#4e73df",
          data: groupAgesIntoIntervals(ageData),
          maxBarThickness: 25,
        },
      ],
    }),
    [ageData]
  );
  const placeholder_stepOptions = useMemo<ChartOptions<'bar'>>(
    () => ({
      normalized: generalOptions.normalized,
      animation: generalOptions.animation,
      maintainAspectRatio: generalOptions.maintainAspectRatio,
      layout: {
        padding: {
          top: 30  // Increase this value as needed
        },
        // ...layoutOptions.normalLayout
      },
      scales: {
        x: {
          type: "category",
          grid: axesOptions.disabledGrid,
          // ticks: {
          //   maxTicksLimit: axesOptions.maxTicksLimitX,
          // },
        },
        y: {
          ticks: {
            maxTicksLimit: axesOptions.maxTicksLimitY,
            padding: 10,
            callback: function (value, index, values) {
              return value;
            },
          },
          grid: axesOptions.enabledGrid,
        },
      },
      plugins: {
        legend: false,
        tooltip: {
          titleMarginBottom: tooltipOptions.titleMarginBottom,
          titleColor: tooltipOptions.titleColor,
          titleFont: tooltipOptions.titleFont,
          bodyFont: tooltipOptions.bodyFont,
          backgroundColor: tooltipOptions.backgroundColor,
          bodyColor: tooltipOptions.bodyColor,
          borderColor: tooltipOptions.borderColor,
          borderWidth: tooltipOptions.borderWidth,
          displayColors: tooltipOptions.displayColors,
          caretPadding: tooltipOptions.caretPadding,
          padding: tooltipOptions.padding,
          callbacks: {
            title: function (tooltipItems, data) {
              return "Age Range: " + tooltipItems[0].label;
            },
            label: function (context) {
              // console.log(context.dataset.label);
              let datasetLabel = context.dataset.label || "";
              return datasetLabel + ": " + context.parsed.y;
            },
          },
        },
        datalabels: {
          align: 'end',
          anchor: 'end',
          color: '#555',
          font: {
              size: 14,
          },
          formatter: (value, context) => {
              return value; // you can adjust this to format the value as you see fit
          }
      },
        zoom: {
          pan: {
            enabled: zoomOptions.panEnabled,
            mode: "x",
          },
          limits: {
            y: {
              min: 0,
              // max: 30000
            },
          },
          zoom: {
            wheel: {
              enabled: zoomOptions.zoomEnabled,
            },
            pinch: {
              enabled: zoomOptions.zoomEnabled,
            },
            mode: "x",
          },
        },
      },
    }),
    []//timeAxis]
  );

  // useEffect(() => {
  //   setLastUpdatedTimeNew(lastUpdatedTime);
  // }, [lastUpdatedTime]);

  // useEffect(() => {
  //   setStepGraph(placeholder_stepData);
  //   setStepOptions(placeholder_stepOptions);
  // }, [placeholder_stepData, placeholder_stepOptions]);

  const [stepGraph, setStepGraph] = useState<ChartData<'bar'>>(placeholder_stepData);
  const [stepOptions, setStepOptions] = useState<ChartOptions<'bar'>>(placeholder_stepOptions);

  return (
    <div className="card mb-4">
      <div className="stepGraph">
        <div className="d-flex card-header align-items-center justify-content-between">
          Age Ranges
        </div>
        <div className="card-body">
          <div className="chart-steps">
            {/* <canvas id="myAreaChart" width="100%" height={30} /> */}
            <Bar ref={stepRef} data={stepGraph} height={30} width={100} options={stepOptions}></Bar>
          </div>
        </div>
        <div className="card-footer small text-muted">{lastUpdatedTimeNew}</div>
      </div>
    </div>
  );
}
