import React, { useState, useEffect, useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { PlusCircle } from "react-feather";
import { GlobalFilter } from "../GlobalFilter";
import { useNavigate } from "react-router-dom";
import Global from "../../utils/Global";
import { FiEdit2 } from "react-icons/fi";
import EditAccountTypeModal from "../Modals/EditAccountTypeModal";
import EditAccountStatusModal from "../Modals/EditAccountStatusModal";
import EditAccountPhoneModal from "../Modals/EditAccountPhoneModal";
import ConfirmAccountTypeModal from "../Modals/ConfirmAccountTypeModal";
import ConfirmAccountStatusModal from "../Modals/ConfirmAccountStatusModal";
import patient_account_types from "../../config/patient_account_types";
import account_status from "../../config/account_status";
import Subject from "../../utils/Subject";
import store from "../../utils/Redux/store";
import { updateUserSubjects } from "../../utils/Redux/actions";
import PatchForm from "../../utils/Form/PatchForm";
import ErrorPopup from "../../components/Modals/ErrorPopup";

export function AccountSettingsTable({ isLoading, subject, setRefreshData }) {
  const [showEditTypeModal, setShowEditTypeModal] = useState(false);
  const [showConfirmTypeModal, setShowConfirmTypeModal] = useState(false);
  const [confirmError, setConfirmError] = useState("");

  const [showEditStatusModal, setShowEditStatusModal] = useState(false);
  const [showConfirmStatusModal, setShowConfirmStatusModal] = useState(false);
  const [showEditPhoneModal, setShowEditPhoneModal] = useState(false);

  const [subjectForm, setSubjectForm] = useState(null);
  const [verifying, setVerifying] = useState(null);
  const [showError, setShowError] = useState(false);

  const editAccountType = () => {
    setSubjectForm(Subject.createPatchForm(subject));
    setShowEditTypeModal(true);
  };

  const editAccountStatus = () => {
    setSubjectForm(Subject.createPatchForm(subject));
    setShowEditStatusModal(true);
  };

  const editAccountPhone = () => {
    setSubjectForm(Subject.createPatchForm(subject));
    setShowEditPhoneModal(true);
  };

  const onSavePressed = async () => {
    if (verifying) {
      return;
    }
    setVerifying(true);

    try {
      const new_subject = Subject.createFromForm(subjectForm);
    } catch (error) {
      setSubjectForm(PatchForm.copy(error.form));
      console.log(error.form);
      setVerifying(false);
      return;
    }

    Global.localAppHandler
      .app_patch_subject(subjectForm)
      .then((updated_subject) => {
        setShowConfirmTypeModal(false);
        setShowConfirmStatusModal(false);
        setShowEditPhoneModal(false);
        store.dispatch(updateUserSubjects(null));
        setRefreshData((prevState) => !prevState);
      })
      .catch((returnStatus) => {
        if (returnStatus.message?.includes("UsernameExistsException")) {
          setConfirmError("Phone number already in use. Enter another phone number.");
        } else {
          setConfirmError("There was an error, please try again.");
        }
        console.log(returnStatus);
      });

    setVerifying(false);
  };

  return (
    <>
      <EditAccountTypeModal
        isOpen={showEditTypeModal}
        handleClose={() => {
          setShowEditTypeModal(false);
        }}
        handleSave={() => {
          setShowEditTypeModal(false);
          setShowConfirmTypeModal(true);
        }}
        subject={subject}
        subjectForm={subjectForm}
        setSubjectForm={setSubjectForm}
      />
      <ConfirmAccountTypeModal
        isOpen={showConfirmTypeModal}
        handleClose={() => {
          setShowConfirmTypeModal(false);
          setShowEditTypeModal(true);
          setConfirmError("");
        }}
        handleSave={() => {
          onSavePressed();
          // console.log(subjectForm);
          // setShowConfirmTypeModal(false);
        }}
        subjectForm={subjectForm}
        setSubjectForm={setSubjectForm}
        setErrorText={setConfirmError}
        errorText={confirmError}
      />

      <EditAccountStatusModal
        isOpen={showEditStatusModal}
        handleClose={() => {
          setShowEditStatusModal(false);
        }}
        handleSave={() => {
          setShowEditStatusModal(false);
          setShowConfirmStatusModal(true);
        }}
        subject={subject}
      />
      <ConfirmAccountStatusModal
        isOpen={showConfirmStatusModal}
        handleClose={() => {
          setShowConfirmStatusModal(false);
          setShowEditStatusModal(true);
          setConfirmError("");
        }}
        handleSave={() => {
          onSavePressed();
        }}
        subject={subject}
        subjectForm={subjectForm}
        setSubjectForm={setSubjectForm}
        setErrorText={setConfirmError}
        errorText={confirmError}
      />

      <EditAccountPhoneModal
        isOpen={showEditPhoneModal}
        handleClose={() => {
          setShowEditPhoneModal(false);
          setConfirmError("");
        }}
        handleSave={() => {
          onSavePressed();
        }}
        subjectForm={subjectForm}
        setSubjectForm={setSubjectForm}
        setErrorText={setConfirmError}
        errorText={confirmError}
      />

      <div className="card mb-4">
        <div className="d-flex card-header align-items-center justify-content-between">
          Account Settings
          <div className="d-flex align-items-center"></div>
        </div>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>
                  Account Type <FiEdit2 onClick={editAccountType} className="mb-1" style={{ marginLeft: "10px" }} />
                </th>
                <th>
                  Account Status <FiEdit2 onClick={editAccountStatus} className="mb-1" style={{ marginLeft: "10px" }} />
                </th>
                <th>
                  Contact Phone{" "}
                  {subject?.account_type === patient_account_types.remote && subject?.account_status === account_status.pending ? (
                    <FiEdit2 onClick={editAccountPhone} className="mb-1" style={{ marginLeft: "10px" }} />
                  ) : (
                    ""
                  )}
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td>Loading...</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>{subject?.account_type ? subject.account_type : "n/a"}</td>
                  <td>{subject?.account_status ? subject.account_status : "n/a"}</td>
                  <td>{subject?.phone_number ? subject.phone_number : "n/a"}</td>
                </tr>
              </tbody>
            )}
          </table>
          {showError ? (
            <ErrorPopup
              message={"Error creating subject. Please try again."}
              onClose={() => {
                setShowError(false);
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
