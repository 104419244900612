import React from 'react';
import patient_account_types from '../../config/patient_account_types';

const RadioButton = ({ id, label, checked, onChange, name }) => {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          id={id}
          type="radio"
          name={name} // Assuming all radio buttons are part of the same group
          checked={checked}
          onChange={onChange}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
    );
  };

const AccountTypeRadioButtons = ({ accountType, handleSelect }) => {
  return (
    <div className="mb-3">
      <label className="medium mb-1">
        Will this user be part of a remote monitoring program?
      </label>
      <RadioButton
        id="remoteaccess"
        label="Remote Access"
        checked={accountType === patient_account_types.remote}
        onChange={() => handleSelect(patient_account_types.remote)}
        name={"accounttype"}
      />
      <RadioButton
        id="inclinic"
        label="In-Clinic Only"
        checked={accountType === patient_account_types.inclinic}
        onChange={() => handleSelect(patient_account_types.inclinic)}
        name={"accounttype"}
      />
    </div>
  );
};

export default AccountTypeRadioButtons;