import "./App.css";
import { Navigate, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./app/pages/Login";
import Dashboard from "./app/pages/Dashboard";
import AddSubject from "./app/pages/AddSubject";
import EditSubject from "./app/pages/EditSubject";
// import config from "./aws-exports-manual.js";
import { Amplify, Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import ForgotPassword from "./app/pages/ForgotPassword";
import ForceChangePassword from "./app/pages/ForceChangePassword";
import ConfirmCode from "./app/pages/ConfirmCode";
import Security from "./app/pages/Security";
import Profile from "./app/pages/Profile";
import Billing from "./app/pages/Billing";
import Error404 from "./app/pages/Error404";
import ManageTeam from "./app/pages/ManageTeam";
import ChartOverview from "./app/pages/ChartOverview";
import ChartTrials from "./app/pages/ChartTrials";
import ManageSubject from "./app/pages/ManageSubject";
import ChartDetailed from "./app/pages/ChartDetailed";
import StudyOverview from "./app/pages/StudyOverview";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import GaitMetrics from "./app/pages/GaitMetrics";
import PrivacyPolicy from "./app/pages/PrivacyPolicy";
import TermsAndConditions from "./app/pages/TermsAndConditions";
import Global from "./app/utils/Global";
import LocalAppHandler from "./app/utils/Services/LocalAppHandler";
import { Provider as ReduxProvider } from 'react-redux';
import store from "./app/utils/Redux/store";
import ReviewReports from "./app/pages/ReviewReports";
import RemoteAlerts from "./app/pages/RemoteAlerts";
import Reports from "./app/pages/Reports";
// Testing manual deployments again

function App() {
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const updateUICallback = () => {
    Global.localAppHandler.app_check_user_status().then((user) => {
      setUser(user);
      // console.log(user)
    }).catch((returnStatus) => {
      setUser(null)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (Global.localAppHandler === null) {
      Global.localAppHandler = new LocalAppHandler();
      Global.localAppHandler.app_setup_auth_listener(updateUICallback);
    }
  }, []);
  
  if (user !== undefined && user !== null) {
    if (loading) {
      // this exists so that if the user data hasn't loaded yet we don't show data on the page..
      return (
        <Authenticator.Provider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Router>
              <Routes>
              </Routes>
            </Router>
          </LocalizationProvider>
        </Authenticator.Provider>
      )
    }
    return (
      <ReduxProvider store={store}>
      <Authenticator.Provider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <Routes>
              <Route path="/recover" exact element={<ForgotPassword />} />
              <Route path="/confirmReset" exact element={<ConfirmCode />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/studyOverview" element={<StudyOverview/> }/>
              <Route path="/addSubject" element={<AddSubject />} />
              {/* <Route path="/dashboard/:providerCode/:patientId/:patientName/overview" exact element={<ChartOverview />} /> */}
              <Route path="/dashboard/:subjectId/trials/:trialId" exact element={<ChartTrials />} />
              <Route path="/dashboard/:subjectId/trials" exact element={<ChartTrials />} />
              <Route path="/dashboard/:subjectId/edit" exact element={<EditSubject />} />
              <Route path="/dashboard/:subjectId/overview" exact element={<ManageSubject />} />
              {/* <Route path="/dashboard/:providerCode/:patientId/:patientName/detailed" exact element={<ChartDetailed />} /> */}
              <Route path="/dashboard/:subjectId/metrics" exact element={<GaitMetrics />} />
              <Route path="/dashboard/:subjectId/reports" exact element={<Reports />} />
              <Route path="/manageTeam" exact element={<ManageTeam />} />
              {/* <Route path="/reviewReports" exact element={<ReviewReports/>}/> */}
              <Route path="/remoteAlerts" exact element={<RemoteAlerts/>} />
              <Route path="/profile" exact element={<Profile />} />
              <Route path="/billing" exact element={<Billing />} />
              <Route path="/security" exact element={<Security />} />
              {/* <Route path="/privacy-policy" exact element={<PrivacyPolicy />} /> */}
              {/* <Route path="/terms-and-conditions" exact element={<TermsAndConditions />} /> */}
              <Route path="/error-404" exact element={<Error404 />} />
              <Route path="/forceChange" exact element={<Navigate to="/dashboard" replace />} />
              <Route path="/login" exact element={<Navigate to="/dashboard" replace />} />
              <Route path="/" exact element={<Navigate to="/dashboard" replace />} />
              <Route path="*" element={<Navigate to="/error-404" replace />} />
            </Routes>
          </Router>
        </LocalizationProvider>
      </Authenticator.Provider>
      </ReduxProvider>
    );
  } else if (user !== undefined) {
    // console.log("unauth");
    return (
      <ReduxProvider store={store}>
      <Authenticator.Provider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <Routes>
              <Route path="/login" exact element={<Login />} />
              <Route path="/recover" exact element={<ForgotPassword />} />
              <Route path="/forceChange" exact element={<ForceChangePassword />} />
              <Route path="/confirmReset" exact element={<ConfirmCode />} />
              {/* <Route path="/privacy-policy" exact element={<PrivacyPolicy />} /> */}
              {/* <Route path="/terms-and-conditions" exact element={<TermsAndConditions />} /> */}
              <Route path="/" exact element={<Navigate to="/login" replace />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Router>
        </LocalizationProvider>
      </Authenticator.Provider>
      </ReduxProvider>
    );
  }
}

export default App;
