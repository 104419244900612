// DateInput.js
import React, { useRef } from 'react';
import MaskedInput from 'react-text-mask';
import theme from "../../core/theme";
import '../../css/DateInput.css';

const DateInput = ({ errorText, description, disabled, onChange, value, ...props }) => {
    const mask = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; // Mask for MM-DD-YYYY
    const inputRef = useRef(null); // Create a ref for the input

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
            const { selectionStart } = event.target;
            if (value[selectionStart - 1] === '-') {
                // Move cursor back before the dash
                event.preventDefault(); // Prevent the default backspace action
                const newPosition = selectionStart - 1;
                inputRef.current.setSelectionRange(newPosition, newPosition);
            }
        }
    };

    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <>
        <MaskedInput
          mask={mask}
          ref={inputRef} // Attach the ref to the input
          className={`date-input ${disabled ? 'disabled' : ''} ${errorText ? 'error has-error' : ''}`}
          placeholder="MM-DD-YYYY"
          guide={false}
          disabled={disabled}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown} // Add the key down event handler
          style={{
            marginBottom: 10, 
            padding: 5, 
            borderRadius: 5, 
            borderWidth: 1
          }}
          {...props}
        />
        {description && !errorText ? <div className="small mb-1">{description}</div> : null}
        {errorText ? <div className="small mb-1 error-text">{errorText}</div> : null}
        </>
    );
};

export default DateInput;