import APIService from "./Services/APIService";
import Form from "./Form/Form";
import FieldWrapperSingle from "./Form/FieldWrapperSingle";
import FormError from "./Errors/FormError";
import { usernameValidator } from "./validators/usernameValidator";
import { emailValidator } from "./validators/emailValidator";
import { accountTypeValidator } from "./validators/accountTypeValidator";
import PatchForm from "./Form/PatchForm";
import PatchWrapperSingle from "./Form/PatchWrapperSingle";

export default class User {
  constructor({ user_id, name, account_type, status = null, email = null }) {
    this._user_id = user_id;
    this._name = name;
    this._account_type = account_type;
    this._status = status;
    this._email = email;
  }

  get user_id() {
    return this._user_id;
  }
  set user_id(user_id) {
    this._user_id = user_id;
  }

  get name() {
    return this._name;
  }
  set name(name) {
    let error = usernameValidator(name);
    if (error) {
      throw new Error(error);
    }
    this._name = name;
  }

  get account_type() {
    return this._account_type;
  }
  set account_type(account_type) {
    let error = accountTypeValidator(account_type);
    if (error) {
      throw new Error(error);
    }
    this._account_type = account_type;
  }

  get status() {
    return this._status;
  }
  set status(status) {
    this._status = status;
  }

  get email() {
    return this._email;
  }
  set email(email) {
    let error = emailValidator(email);
    if (error) {
      throw new Error(error);
    }
    this._email = email;
  }

  static createFromForm(user_form) {
    const user = new User({});
    const fields = Object.values(user_form);
    let exceptionOccurred = false;

    fields.forEach((field) => {
      try {
        user[field.attribute_name] = field.getUI();
      } catch (error) {
        console.log("ERROR OCCURRED")
        console.log(field.attribute_name)
        console.log(error)
        field.error = error.message;
        exceptionOccurred = true;
      }
    })

    if (exceptionOccurred) {
      throw new FormError("UserForm Error Occurred!", user_form);
    } else {
      return user;
    }
  }

  static createFromAPI(user_api) {
    return new User({user_id: user_api.user_id, name: user_api.name, account_type: user_api.account_type, status: user_api.status})
  }

  static createForm(user = null) {
    return new Form({
      properties: {
        user_id: new FieldWrapperSingle({ attribute_name: "user_id", value: user ? user.user_id : null, error: "", value_validator: null }),
        name: new FieldWrapperSingle({ attribute_name: "name", value: user ? user.name : null, error: "", value_validator: null }),
        account_type: new FieldWrapperSingle({ attribute_name: "account_type", value: user ? user.account_type : null, error: "", value_validator: null }),
        status: new FieldWrapperSingle({ attribute_name: "status", value: user ? user.status : null, error: "", value_validator: null }),
        email: new FieldWrapperSingle({ attribute_name: "email", value: user ? user.email : null, error: "", value_validator: null }),
      },
    });
  }

  static createPatchForm(user = null) {
    return new PatchForm({
      properties: {
        user_id: new PatchWrapperSingle({ attribute_name: "user_id", value: user ? user.user_id : null, error: "", value_validator: null }),
        name: new PatchWrapperSingle({ attribute_name: "name", value: user ? user.name : null, error: "", value_validator: null }),
        account_type: new PatchWrapperSingle({ attribute_name: "account_type", value: user ? user.account_type : null, error: "", value_validator: null }),
        status: new PatchWrapperSingle({ attribute_name: "status", value: user ? user.status : null, error: "", value_validator: null }),
        email: new PatchWrapperSingle({ attribute_name: "email", value: user ? user.email : null, error: "", value_validator: null }),
      },
    });
  }

  static sendToAPI(user) {
    const cleanedUser = {};
    cleanedUser['name'] = user.name;
    cleanedUser['email'] = user.email;
    cleanedUser['account_type'] = user.account_type;
    return cleanedUser;
  }

  static getUser() {
    return new Promise((resolve, reject) => {
      APIService.get("/user/").then((user) => {
        resolve(User.createFromAPI(user))
      }).catch((err) => {
        reject(err);
      })
    });
  }

  static getUserList(include_clinic_users = false, account_type = null, account_status = null) {
    let path_base = "/user/" //+ user_id;
    let query_params = [];
    if (include_clinic_users) {
      query_params.push("include_clinic_users=true");
    }
    if (account_type) {
      query_params.push("account_type=" + account_type);
    }
    if (account_status) {
      query_params.push("account_status=" + account_status);
    }

    return new Promise((resolve, reject) => {
      APIService.get(path_base, query_params)
        .then((team) => {
          resolve(team["clinic_users"].map((member) => User.createFromAPI(member)));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static postUser(user) {
    let path_base = "/user/";
    let body = User.sendToAPI(user);

    return new Promise((resolve, reject) => {
      APIService.post(path_base, body)
        .then((addedUser) => {
          resolve(addedUser)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  static patchUser(userForm) {
    let path_base = "/user/" + userForm.user_id.getUI();
    let body = userForm.generatePatchSet();

    return new Promise((resolve, reject) => {
      if (body.length === 0) {
        resolve(null);
      } else {
        // resolve();
        APIService.patch(path_base, body).then((user) => {
          resolve(User.createFromAPI(user))
        }).catch((err) => {
          reject(err);
        })
      }
    })
  }
}
