import React, { useState, useEffect, useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { PlusCircle, RefreshCw } from "react-feather";
import { GlobalFilter } from "../GlobalFilter";
import { useNavigate } from "react-router-dom";
import Global from "../../utils/Global";
import Subject from "../../utils/Subject";
import RefreshButton from "../RefreshButton";
import patient_account_types from "../../config/patient_account_types";
import account_status from "../../config/account_status";
import ReactSwitch from "react-switch";
import moment from "moment";

export function RemoteAlertsTable({ subjectsLoading, setSubjectsLoading, subjects, ...props }) {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState(null);
  const [subjectsState, setSubjectsState] = useState(subjects);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  useEffect(() => {
    console.log(subjects);
    setSubjectsState(subjects);
  }, [subjects]);

  const toggleFilter = (newFilter) => {
    const isCurrentlyActive = activeFilter === newFilter;
    setFilter(isCurrentlyActive ? "" : newFilter); // Clear or apply new filter
    setActiveFilter(isCurrentlyActive ? null : newFilter); // Set active filter for UI feedback
  };

  const handleToggle = (subjectId) => {
    setSubjectsState((prevSubjects) =>
      prevSubjects.map((subject) => (subject.subject_id === subjectId ? { ...subject, report_reviewed: !subject.report_reviewed } : subject))
    );
    // Optionally, you can also update the backend here
    console.log(`Toggled report: ${subjectId}`);
  };

  const onRowClick = (cell) => {
    const subject = cell.row.original;
    Global.localAppHandler
      .app_set_subject(subject)
      .then((subject_id) => {
        navigate("/dashboard/" + subject_id + "/overview");
      })
      .catch((returnStatus) => {
        // console.log("this should never happen");
      });

    // }
  };

  const initialState = {
    sortBy: [
      {
        id: "subject_id", // the accessor of the column you want to sort by
        desc: false, // false for ascending, true for descending
      },
    ],
  };

  const patientsData = useMemo(() => {
    if (!filter) return subjectsState; // No filter applied
    return subjectsState.filter((subject) => {
      if (filter === "Show Pending Only") {
        return subject.account_status === account_status.pending;
      } else if (filter === "Show Active Only") {
        return subject.account_status === account_status.active;
      } else {
        return false;
      }
    });
  }, [subjectsState, filter]);

  const patientsColumns = useMemo(
    () =>
      subjectsState && subjectsState[0]
        ? [
            {
              Header: "Subject ID",
              accessor: "name",
            },
            {
              Header: "Primary Condition(s)",
              accessor: (row) => (row.primary_conditions[0] ? row.primary_conditions[0].description : ""),
              id: "primary_conditions",
            },
            {
              Header: "Account Status",
              accessor: "account_status",
            },
            {
              Header: "Days Since Last Collection",
              accessor: (row) =>
                row.account_status === account_status.pending
                  ? "No Collection History"
                  : row.most_recent_collection_date
                  ? moment()
                      .startOf("day")
                      .diff(moment(new Date(row.most_recent_collection_date)).startOf("day"), "days")
                  : "n/a",
            },
          ]
        : [],
    [subjectsState]
  );

  const tableInstance = useTable(
    {
      columns: patientsColumns,
      data: patientsData,
      initialState, // include initialState here
    },
    useGlobalFilter,
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state } = tableInstance;

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
        {/* Plus button */}

        <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />

        <div style={{ display: "flex", alignItems: "center" }}>
          {["Show Pending Only", "Show Active Only"].map((status, index, array) => (
            <React.Fragment key={status}>
              <button
                style={{
                  background: "none",
                  color: activeFilter === status ? "blue" : "gray",
                  cursor: "pointer",
                  border: "none",
                  textDecoration: activeFilter === status ? "underline" : "",
                  padding: "0 10px",
                }}
                onClick={() => toggleFilter(status)}
              >
                {status}
              </button>
              {index < array.length - 1 && <span style={{ margin: "0 5px" }}>/</span>}
            </React.Fragment>
          ))}
        </div>
      </div>
      <table className="table table-bordered" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, headerGroupIdx) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIdx}>
              {headerGroup.headers.map((column, columnIdx) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIdx}>
                  {column.render("Header")}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <img src={require("../../images/down_arrow.png")} alt="" />
                    ) : (
                      <img src={require("../../images/up_arrow.png")} alt="" />
                    )
                  ) : column.Header === "Actions" ? (
                    ""
                  ) : (
                    <img src={require("../../images/default.png")} alt="" />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {subjectsLoading ? (
            <tr>
              <td colSpan={patientsColumns.length}>Loading...</td>
            </tr>
          ) : rows.length > 0 ? (
            rows.map((row, rowIdx) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}
                key={rowIdx}
                onMouseEnter={() => setHoveredRowIndex(rowIdx)}
                onMouseLeave={() => setHoveredRowIndex(null)}
                style={{
                  cursor: "pointer",
                }}
                >
                  {row.cells.map((cell, cellIdx) => (
                    <td
                    key={cellIdx} 
                    className={hoveredRowIndex === rowIdx ? "link-primary" : ""}
                    {...cell.getCellProps()}
                    onClick={() => {
                      onRowClick(cell);
                    }}
                    >{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={patientsColumns.length}>No Alerts Pending.</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
