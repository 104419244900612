import React, { useState, useEffect } from "react";
import User from "../../utils/User";
import Global from "../../utils/Global";
import PatchForm from "../../utils/Form/PatchForm";
import account_status from "../../config/account_status";

const DeactivateModal = ({ isOpen, selectedUser, handleClose, handleSave }) => {
  const [verifying, setVerifying] = useState(false);
  const [userForm, setUserForm] = useState(null)
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    setErrorText("");
    setUserForm(User.createPatchForm(selectedUser))
  }, [selectedUser])

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              {"Confirm Deactivation: "} <b>{selectedUser.name}</b>
            </h5>
            <button className="btn-close" type="button" aria-label="Close" onClick={() => {
              handleClose()
              }}></button>
          </div>
          <div className="modal-body">
            <p>{"Are you sure you want to deactivate this user?"}</p>
          </div>
          {errorText && <div className="mx-4 tiny alert alert-danger">{errorText}</div>}
          <div className="modal-footer">
            <button className="btn btn-secondary" type="button" onClick={() => {
                handleClose()
            }}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                if (verifying) {
                  return;
                }
                setVerifying(true);
            
                userForm?.status.handleInputChange(account_status.inactive);
                console.log(userForm)
                Global.localAppHandler
                .app_patch_user(userForm)
                .then((returnStatus) => {
                  handleSave();
                })
                .catch((returnStatus) => {
                  setErrorText("There was an error updating the user's status.");
                })
                setVerifying(false);
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeactivateModal;
{
  /* <div className="modal fade" id="exampleModalCenter" tabindex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">Selected Option: </h5>
            <p>{selectedOption}</p>
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
        </div>
        <div className="modal-body">...</div>
        <div className="modal-footer"><button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button><button class="btn btn-primary" type="button">Save changes</button></div>
    </div>
</div>
</div> */
}
